import axios from "axios";
import React, { useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import HomeProductCrousel from "../Helpers/HomeProductCrousel";
import Layout from "../Partials/Layout";
import Banner from "./Banner";

export default function HomeThree() {
  // const { products } = datas;
  // const brands = [];
  // products.forEach((product) => {
  //   brands.push(product.brand);
  // });

  const products = [];
  const brands = [];

  const [featuresProducts, setFeaturesProducts] = useState([]);
  const [bestSellingProducts, setBestSellingProducts] = useState([]);
  const [popularProducts, setPopularProducts] = useState([]);
  const [trendingProducts, setTrendingProducts] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [homeCatProducts, setHomeCatProducts] = useState([]);
  const [brandLogos, setBrandLogos] = useState([]);

  useEffect(() => {
    const fetchFeaturesProducts = async () => {
      try {
        const resp = await axios.get(Constants.api + "products?is_feature=1");
        if (resp.data.success) {
          setFeaturesProducts(resp.data.products);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchFeaturesProducts();
  }, []);

  useEffect(() => {
    const fetchBestSellingProducts = async () => {
      try {
        const resp = await axios.get(Constants.api + "products?best_selling=1");
        if (resp.data.success) {
          setBestSellingProducts(resp.data.products);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchBestSellingProducts();
  }, []);

  //console.log(bestSellingProducts);

  useEffect(() => {
    const fetchPopularProducts = async () => {
      try {
        const resp = await axios.get(Constants.api + "products?is_popular=1");
        if (resp.data.success) {
          setPopularProducts(resp.data.products);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchPopularProducts();
  }, []);

  useEffect(() => {
    const fetchTrendingProducts = async () => {
      try {
        const resp = await axios.get(Constants.api + "products?is_trending=1");
        if (resp.data.success) {
          setTrendingProducts(resp.data.products);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchTrendingProducts();
  }, []);

  useEffect(() => {
    const fetchNewProducts = async () => {
      try {
        const resp = await axios.get(Constants.api + "products?is_new=1");
        if (resp.data.success) {
          setNewProducts(resp.data.products);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchNewProducts();
  }, []);

  useEffect(() => {
    const fetchNewProducts = async () => {
      try {
        const resp = await axios.get(Constants.api + "home-category-products");
        if (resp.data.success) {
          setHomeCatProducts(resp.data.categories);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchNewProducts();
  }, []);

  useEffect(() => {
    const fetchBrandLogos = async () => {
      try {
        const resp = await axios.get(Constants.api + "brands");
        if (resp.data.success) {
          setBrandLogos(resp.data.brands);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchBrandLogos();
  }, []);

  return (
    <>
      <Layout type={3} childrenClasses="pt-0">
        <Banner className="banner-wrapper mb-[30px]" />
        {/* <BrandSection
          type={3}
          sectionTitle="Shop by Brand"
          className="brand-section-wrapper mb-[60px]"
        /> */}
        {/* <SectionStyleThree
          type={3}
          products={featuresProducts}
          sectionTitle="Features Products"
          seeMoreUrl="/all-products"
          className="new-products mb-[60px]"
        /> */}
        {/* <ProductsAds
          ads={[`${process.env.PUBLIC_URL}/assets/images/ads-3.png`]}
          className="products-ads-section mb-[60px]"
        /> */}

        {/* <SectionStyleOneHmThree
          type={3}
          products={products}
          brands={brands}
          categoryTitle="Mobile & Tablet"
          sectionTitle="Gamer World"
          seeMoreUrl="/all-products"
          className="category-products mb-[60px]"
        /> */}

        {/* <section className="ptb-60">
          <div className="our-container w-full">
            <div className="our-box-client">
              <div className="Our-client">
                <div className="client-head">
                  <h1>Featured Brands</h1>
                </div>
                <div className="more-client">
                  <div className="btn-view-all">
                    <div>
                      <a href="/all-products">
                        <div class="flex space-x-2 items-center">
                          <p class="text-base font-600 text-qblack">
                            View More
                          </p>

                          <span class="animate-right-dir">
                            <svg
                              width="17"
                              height="14"
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.0225 6.00243C13.9998 6.03738 13.9772 6.06941 13.9545 6.10436C13.8724 6.10727 13.7904 6.11601 13.7083 6.11601C9.93521 6.11601 6.16215 6.11601 2.38909 6.11601C1.87111 6.11601 1.35313 6.10728 0.835147 6.12475C0.351131 6.14514 0.00863998 6.51501 0.000148475 6.981C-0.00834303 7.45864 0.3483 7.83725 0.837977 7.8722C0.956858 7.88094 1.07857 7.87511 1.20028 7.87511C5.33565 7.87803 9.46818 7.87803 13.6035 7.88094C13.7253 7.88094 13.8498 7.88094 13.9715 7.88094C14.0026 7.93627 14.031 7.9887 14.0621 8.04403C13.9404 8.12267 13.7988 8.18383 13.697 8.28576C12.3355 9.67499 10.9797 11.0671 9.62669 12.4651C9.26155 12.8437 9.25306 13.3767 9.58423 13.732C9.91823 14.0902 10.4419 14.099 10.8127 13.7233C12.7855 11.702 14.7556 9.6779 16.7199 7.64794C17.0907 7.26351 17.0851 6.73053 16.7171 6.34901C14.7697 4.33652 12.8167 2.32987 10.858 0.329035C10.7278 0.195063 10.5466 0.0873038 10.3683 0.0319679C10.0088 -0.0757916 9.63235 0.116428 9.44554 0.451356C9.26438 0.78046 9.31533 1.20859 9.60687 1.51148C10.6768 2.62111 11.7524 3.72492 12.8308 4.82581C13.2271 5.2219 13.6262 5.60925 14.0225 6.00243Z"
                                fill="white"
                              ></path>
                              <path
                                d="M14.0225 6.00241C13.6262 5.60923 13.2243 5.22188 12.8336 4.82288C11.7552 3.72199 10.6796 2.61818 9.60971 1.50855C9.31816 1.20566 9.26721 0.77753 9.44837 0.448427C9.63518 0.113498 10.0116 -0.0787213 10.3711 0.0290382C10.5466 0.0814617 10.7278 0.192134 10.8608 0.326105C12.8195 2.32694 14.7697 4.33359 16.7199 6.34608C17.0879 6.72469 17.0936 7.26058 16.7228 7.64501C14.7584 9.67497 12.7884 11.6991 10.8155 13.7203C10.4475 14.0989 9.92106 14.0873 9.58706 13.7291C9.25589 13.3737 9.26155 12.8408 9.62952 12.4622C10.9825 11.0642 12.3383 9.67206 13.6998 8.28284C13.8017 8.1809 13.9404 8.11974 14.0649 8.0411C14.0338 7.98577 14.0055 7.93334 13.9743 7.87801C13.8526 7.87801 13.7281 7.87801 13.6064 7.87801C9.47101 7.8751 5.33848 7.8751 1.20311 7.87218C1.0814 7.87218 0.962519 7.87801 0.840808 7.86927C0.3483 7.84015 -0.00834304 7.45862 0.00014847 6.98098C0.00863998 6.515 0.351131 6.14512 0.832316 6.12764C1.3503 6.10726 1.86828 6.11891 2.38626 6.11891C6.16215 6.11599 9.93521 6.11599 13.7083 6.11599C13.7904 6.11599 13.8724 6.10726 13.9574 6.10143C13.9772 6.0694 13.9998 6.03445 14.0225 6.00241Z"
                                fill="black"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {brandLogos.length > 0 && (
                <div className="client-logo">
                  {brandLogos.map((logo) => (
                    <div className="our-client-logos" key={logo.id}>
                      <img src={logo.image_url} alt="" className="img-fluid" />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </section> */}

        <HomeProductCrousel
          type={3}
          products={brandLogos}
          sectionTitle="Featured Brands"
          seeMoreUrl="/all-products"
          className="new-products mb-[60px]"
        />

        <HomeProductCrousel
          type={3}
          products={popularProducts}
          sectionTitle="Popular Deals"
          seeMoreUrl="/all-products"
          className="new-products mb-[60px]"
        />

        <HomeProductCrousel
          type={3}
          products={trendingProducts}
          sectionTitle="Trending Products"
          seeMoreUrl="/all-products"
          className="new-products mb-[60px]"
        />

        <HomeProductCrousel
          type={3}
          products={newProducts}
          sectionTitle="New Arrivals          "
          seeMoreUrl="/all-products"
          className="new-products mb-[60px]"
        />

        <HomeProductCrousel
          type={3}
          products={bestSellingProducts}
          sectionTitle="Best Selling Products"
          seeMoreUrl="/all-products"
          className="new-products mb-[60px]"
        />

        {homeCatProducts.map((homeCat) => (
          <React.Fragment key={homeCat.id}>
            {homeCat.product_array.length > 0 && (
              <HomeProductCrousel
                type={3}
                products={homeCat.product_array}
                sectionTitle={homeCat.title}
                seeMoreUrl="/all-products"
                className="new-products mb-[60px]"
              />
            )}
          </React.Fragment>
        ))}

        {/* <ViewMoreTitle
          className="top-selling-product mb-[60px]"
          seeMoreUrl="/all-products"
          categoryTitle="Best Selling Products"
        >
          <SectionStyleTwo type={3} products={bestSellingProducts} />
        </ViewMoreTitle> */}

        {/* <ProductsAds
          ads={[
            `${process.env.PUBLIC_URL}/assets/images/ads-1.png`,
            `${process.env.PUBLIC_URL}/assets/images/ads-2.png`,
          ]}
          sectionHeight="sm:h-[295px] h-full"
          className="products-ads-section mb-[60px]"
        /> */}
        {/* <SectionStyleOneHmThree
          type={3}
          categoryBackground={`${process.env.PUBLIC_URL}/assets/images/section-category-2.jpg`}
          products={products.slice(4, products.length)}
          brands={brands}
          categoryTitle="Electronics"
          sectionTitle="Popular Sales"
          seeMoreUrl="/all-products"
          className="category-products mb-[60px]"
        /> */}
        {/* <CampaignCountDown
          className="mb-[60px]"
          lastDate="2023-10-04 4:00:00"
        /> */}
        {/* <SectionStyleFour
          products={products}
          sectionTitle="Popular Sales"
          seeMoreUrl="/all-products"
          className="category-products mb-[60px]"
        /> */}
      </Layout>
    </>
  );
}
