import { useDispatch, useSelector } from "react-redux";
import { addCart, delCart, delCartRow, resetCart } from "../../redux/action";

import axios from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import DropdownSelect from "react-dropdown-select";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import * as Constants from "../../Constants/index.js";
import InputCom from "../Helpers/InputCom";
import Toast from "../Toast.js";

export default function ProductsTable({ className }) {
  //const productData = useSelector((state) => state.handleCart);
  const productData = useSelector((state) => state.handleCart) ?? [];
  const loginCustomer = JSON.parse(localStorage.getItem("customer_row")) || [];
  //const productData = JSON.parse(localStorage.getItem("cart")) || [];
  console.log("cartData>>>>>>>>>>>", productData);
  // console.log(state);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addItem = (product) => {
    dispatch(addCart(product));
  };
  const removeItem = (product) => {
    dispatch(delCart(product));
  };
  const removeItemRow = (product) => {
    dispatch(delCartRow(product));
  };

  const [statesData, setStatesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOTPLoading, setIsOTPLoading] = useState(false);
  const [isDeliverLoading, setIsDeliverLoading] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState("");

  const [isSendOtp, setIsSendOtp] = useState(false);
  const [otpMobile, setOtpMobile] = useState(0);

  const [checked, setValue] = useState(false);
  const rememberMe = () => {
    setValue(!checked);
  };

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const resp = await axios.get(Constants.api + "states");
        if (resp.data.success) {
          setStatesData(resp.data.states);
          // console.log(resp.data.categories);
          // console.log('fasdfasdf',productCategories);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchStates();
  }, []);

  const [addressData, setAddressData] = useState([]);
  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const requestData = { customerData: loginCustomer };
        const resp = await axios.post(
          Constants.api + "customer-addresses",
          requestData
        );
        if (resp.data.success) {
          setAddressData(resp.data.customer_address);
        } else {
          return <Navigate to="/login" />; //its check login or not from api
        }
      } catch (err) {
        console.error("An error occurred:", err);
      }
    };

    fetchAddress();
  }, []);

  const [measurementData, setMeasurementData] = useState([]);
  useEffect(() => {
    const fetchMeasurements = async () => {
      try {
        const resp = await axios.get(Constants.api + "measurements");
        if (resp.data.success) {
          setMeasurementData(resp.data.measurements);
          // console.log(resp.data.categories);
          // console.log('fasdfasdf',productCategories);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchMeasurements();
  }, []);

  const sendOtp = useFormik({
    initialValues: {
      mobile: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string()
        .matches(/^[0-9]+$/, "Mobile must only contain digits")
        .min(10, "Mobile must be at least 10 digits")
        .max(10, "Mobile must not exceed 10 digits")
        .required("Mobile is required"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      //console.log(values);
      axios
        .post(Constants.api + "login", values)
        .then((response) => {
          if (response && response.data && response.data.success) {
            console.log(response.data.message);
            toast.success(<Toast message={response.data.message} />, {
              position: "top-right",
              autoClose: 2000,
              onClose: () => {
                //navigate("/login");
                setIsSendOtp(!isSendOtp);
                setOtpMobile(values.mobile);
                setIsLoading(false);
              },
            });
          } else {
            toast.error(<Toast message={response.data.message} />, {
              position: "top-right",
              autoClose: 2000,
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Login failed", error);
        });
    },
  });

  const otpVerify = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .matches(/^[0-9]+$/, "OTP must only contain digits")
        .min(6, "OTP must be at least 6 digits")
        .max(6, "OTP must not exceed 6 digits")
        .required("OTP is required"),
    }),
    onSubmit: (values) => {
      values.mobile = otpMobile;
      setIsOTPLoading(true);
      //console.log(values);
      axios
        .post(Constants.api + "otp", values)
        .then((response) => {
          if (response && response.data && response.data.success) {
            console.log(response.data.message);
            console.log(response.data.customer_row);
            localStorage.setItem("isCustomerLoggedIn", "true");
            localStorage.setItem(
              "customer_row",
              JSON.stringify(response.data.customer_row)
            );
            toast.success(<Toast message={response.data.message} />, {
              position: "top-right",
              autoClose: 2000,
              onClose: () => {
                setIsOTPLoading(false);
                //navigate("/profile");
              },
            });
          } else {
            toast.error(<Toast message={response.data.message} />, {
              position: "top-right",
              autoClose: 2000,
            });
            setIsOTPLoading(false);
          }
        })
        .catch((error) => {
          console.error("Login failed", error);
          setIsOTPLoading(false);
        });
    },
  });

  const generateAddressValidationSchema = (isAddressSelected) => {
    return Yup.object({
      requirement: Yup.string().required("Requirement is required"),
      state_id: isAddressSelected
        ? Yup.string()
        : Yup.string().required("State is required"),
      city: isAddressSelected
        ? Yup.string()
        : Yup.string().required("City is required"),
      address: isAddressSelected
        ? Yup.string()
        : Yup.string().required("Address is required"),
      pincode: isAddressSelected
        ? Yup.string()
        : Yup.string().required("Pincode is required"),
    });
  };

  const enquirySubmit = useFormik({
    initialValues: {
      requirement: "",
      state_id: "",
      city: "",
      address: "",
      pincode: "",
    },
    // validationSchema: Yup.object({
    //   state_id: Yup.string().required("State is required"),
    //   city: Yup.string().required("City is required"),
    //   address: Yup.string().required("Address is required"),
    //   pincode: Yup.string().required("Pincode is required"),
    // }),
    validationSchema: generateAddressValidationSchema(!!selectedAddressId),
    onSubmit: (values) => {
      values.product_array = productData;
      values.customer_row = loginCustomer;
      values.selected_address_id = selectedAddressId;
      setIsDeliverLoading(true);
      //console.log(values);
      axios
        .post(Constants.api + "enquiry-submit", values)
        .then((response) => {
          if (response && response.data && response.data.success) {
            console.log(response.data.message);
            toast.success(<Toast message={response.data.message} />, {
              position: "top-right",
              autoClose: 2000,
              onClose: () => {
                setIsDeliverLoading(false);
                localStorage.removeItem(`cart`);
                dispatch(resetCart());
                navigate("/all-products");
              },
            });
          } else {
            toast.error(<Toast message={response.data.message} />, {
              position: "top-right",
              autoClose: 2000,
            });
            setIsDeliverLoading(false);
          }
        })
        .catch((error) => {
          console.error("Form submit failed", error);
          setIsDeliverLoading(false);
        });
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      customer_type: "BUYER",
      company_name: "",
      gst_number: "",
      pan_number: "",
    },
    validationSchema: Yup.object({
      customer_type: Yup.string().required("Customer type is required"),
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      mobile: Yup.string()
        .matches(/^[0-9]+$/, "Mobile must only contain digits")
        .min(10, "Mobile must be at least 10 digits")
        .max(10, "Mobile must not exceed 10 digits")
        .required("Mobile is required"),
      company_name: Yup.string().required("Company name is required"),
      // gst_number: Yup.string()
      //   .matches(
      //     /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
      //     "Invalid GST number"
      //   )
      //   .required("GST number is required"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      // You can send the registration data to your API here using axios
      axios
        .post(Constants.api + "register", values)
        .then((response) => {
          if (response && response.data && response.data.success) {
            console.log(response.data.message);
            toast.success(<Toast message={response.data.message} />, {
              position: "top-right",
              autoClose: 2000,
              onClose: () => {
                setIsLoading(false);
                // navigate("/login");
                formik.resetForm();
              },
            });
          } else {
            toast.error(<Toast message={response.data.message} />, {
              position: "top-right",
              autoClose: 2000,
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Registration failed", error);
          setIsLoading(false);
        });
    },
  });

  return (
    <div className={`w-full ${className || ""}`}>
      <div className="relative w-full overflow-x-auto border border-[#EDEDED]">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
            {/* table heading */}
            <tr className="text-[13px] font-medium text-black bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom uppercase">
              <td className="py-4 pl-10 block whitespace-nowrap min-w-[300px]">
                Selected Product
              </td>
              {/* <td className="py-4 whitespace-nowrap text-center">color</td>
              <td className="py-4 whitespace-nowrap text-center">size</td>
              <td className="py-4 whitespace-nowrap text-center">price</td> */}
              <td className="py-4 whitespace-nowrap  text-center">
                Add quantity
              </td>
              <td className="py-4 whitespace-nowrap  text-center">
                Measurement
              </td>
              <td className="py-4 whitespace-nowrap text-right w-[114px]"></td>
            </tr>
            {/* table heading end */}
            {productData.length > 0 &&
              productData.map((item) => {
                return (
                  <tr
                    className="bg-white border-b hover:bg-gray-50"
                    key={item.id}
                  >
                    <td className="pl-10  py-4  w-[380px]">
                      <div className="flex space-x-6 items-center">
                        <div className="w-[80px] h-[80px] overflow-hidden flex justify-center items-center border border-[#EDEDED]">
                          <img
                            src={`${item.image_url}`}
                            alt="product"
                            className="w-full h-full object-contain"
                          />
                        </div>
                        <div className="flex-1 flex flex-col">
                          <p className="font-medium text-[15px] text-qblack">
                            {item.title}
                          </p>
                        </div>
                      </div>
                    </td>
                    {/* <td className="text-center py-4 px-2">
                    <div className=" flex justify-center items-center">
                      <span className="w-[20px] h-[20px] bg-[#E4BC87] block rounded-full"></span>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-[15px] font-normal">Small</span>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-[15px] font-normal">$38</span>
                    </div>
                  </td> */}
                    <td className=" py-4">
                      <div className="flex justify-center items-center">
                        {/* <InputQuantityCom /> */}
                        <div className="w-[120px] h-[40px] px-[26px] flex items-center border border-qgray-border">
                          <div className="flex justify-between items-center w-full">
                            <button
                              onClick={() => {
                                removeItem(item);
                              }}
                              type="button"
                              className="text-base text-qgray"
                            >
                              -
                            </button>
                            <span className="text-qblack">{item.qty}</span>
                            <button
                              onClick={() => {
                                addItem(item);
                              }}
                              type="button"
                              className="text-base text-qgray"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="text-right py-4">
                      <div className="flex space-x-1 items-center justify-center">
                        <span className="text-[15px] font-normal">
                          {item.measurement}
                        </span>
                      </div>
                    </td>
                    <td className="text-right py-4">
                      <div className="flex space-x-1 items-center justify-center">
                        <button
                          onClick={() => {
                            removeItemRow(item);
                          }}
                          type="button"
                          className="text-base text-qgray"
                        >
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3L5 3.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L3.6 5L0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7C0.7 10.1 1.3 10.1 1.7 9.7L5 6.4L8.3 9.7C8.7 10.1 9.3 10.1 9.7 9.7C10.1 9.3 10.1 8.7 9.7 8.3L6.4 5L9.7 1.7C10.1 1.3 10.1 0.7 9.7 0.3Z"
                              fill="#AAAAAA"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      {localStorage.getItem("isCustomerLoggedIn") === "true" ? (
        <div className="relative w-full overflow-x-auto border border-[#EDEDED] mt-4 p7">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <tbody>
              <tr className="text-[13px] font-medium text-black bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom uppercase">
                <td className="py-4 pl-10 block whitespace-nowrap min-w-[300px]">
                  Product Requirment
                </td>
              </tr>
              <tr>
                <td>
                  <div className="grid grid-cols-3 gap-[15px] pl-5 pt-5 pr-5 pb-5">
                    {addressData.length > 0 &&
                      addressData.map((addressRow, index) => {
                        return (
                          <div className="w-full bg-primarygray p-5 border">
                            <div className="flex justify-between items-center">
                              <p className="title text-[22px] font-semibold">
                                Address #{index + 1}
                              </p>

                              <input
                                type="radio"
                                name="selectedAddress"
                                value={addressRow.id}
                                checked={selectedAddressId === addressRow.id}
                                onChange={() =>
                                  setSelectedAddressId(addressRow.id)
                                }
                              />
                            </div>
                            <div className="mt-5">
                              <table>
                                <tbody>
                                  <tr className="flex mb-3">
                                    <td className="text-base text-qgraytwo w-[70px] block line-clamp-1">
                                      <div>State:</div>
                                    </td>
                                    <td className="text-base text-qblack line-clamp-1 font-medium">
                                      {addressRow.state_name}
                                    </td>
                                  </tr>
                                  <tr className="flex mb-3">
                                    <td className="text-base text-qgraytwo w-[70px] block line-clamp-1">
                                      <div>City:</div>
                                    </td>
                                    <td className="text-base text-qblack line-clamp-1 font-medium">
                                      {addressRow.city_name}
                                    </td>
                                  </tr>
                                  <tr className="flex mb-3">
                                    <td className="text-base text-qgraytwo w-[70px] block line-clamp-1">
                                      <div>Address:</div>
                                    </td>
                                    <td className="text-base text-qblack line-clamp-1 font-medium">
                                      {addressRow.address_one}
                                    </td>
                                  </tr>
                                  <tr className="flex mb-3">
                                    <td className="text-base text-qgraytwo w-[70px] block line-clamp-1">
                                      <div>Pincode:</div>
                                    </td>
                                    <td className="text-base text-qblack line-clamp-1 font-medium">
                                      {addressRow.pincode}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <form onSubmit={enquirySubmit.handleSubmit}>
                    <div className="input-area input-area-cart">
                      <div className="input-feild">
                        <div className="input-state">
                          <div className="input-body input-flex">
                            <div className="input-item mb-5 input-flex input-height">
                              <InputCom
                                placeholder="Requirment"
                                label="Requirment*"
                                type="text"
                                id="requirement"
                                name="requirement"
                                onChange={enquirySubmit.handleChange}
                                onBlur={enquirySubmit.handleBlur}
                                value={enquirySubmit.values.requirement}
                                className={`input-height input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none input-height`}
                              />
                              {enquirySubmit.touched.requirement &&
                                enquirySubmit.errors.requirement ? (
                                <span className="errorMsg mt-6">
                                  {enquirySubmit.errors.requirement}
                                </span>
                              ) : null}
                            </div>

                            {/* <div className="input-item mb-5 input-flex">
                              <label
                                className={`input-label capitalize block  mb-1 text-qgray text-[13px] font-normal`}
                                for="measurement_id"
                              >
                                Measurement*
                              </label>
                              <DropdownSelect
                                options={measurementData.map((option) => ({
                                  label: option.title,
                                  value: option.id,
                                }))}
                                value={enquirySubmit.values.measurement_id}
                                onChange={(selectedOptions) => {
                                  // Update Formik field value
                                  enquirySubmit.setFieldValue(
                                    "measurement_id",
                                    selectedOptions.length > 0
                                      ? selectedOptions[0].value
                                      : ""
                                  );
                                }}
                                searchable={true}
                                dropdownPosition="auto"
                                keepSelectedInList={true}
                                placeholder="Select Measurement"
                              />
                              {enquirySubmit.touched.measurement_id &&
                              enquirySubmit.errors.measurement_id ? (
                                <span className="errorMsg">
                                  {enquirySubmit.errors.measurement_id}
                                </span>
                              ) : null}
                            </div> */}
                          </div>
                        </div>

                        <div className="input-state mt-5">
                          <div className="input-body input-flex">
                            <div className="input-item mb-5 input-flex">
                              <label
                                className={`input-label capitalize block  mb-1 text-qgray text-[13px] font-normal`}
                                for="state_id"
                              >
                                State*
                              </label>
                              <DropdownSelect
                                options={statesData.map((option) => ({
                                  label: option.name,
                                  value: option.id,
                                }))}
                                value={enquirySubmit.values.state_id}
                                onChange={(selectedOptions) => {
                                  // Update Formik field value
                                  enquirySubmit.setFieldValue(
                                    "state_id",
                                    selectedOptions.length > 0
                                      ? selectedOptions[0].value
                                      : ""
                                  );
                                }}
                                searchable={true}
                                dropdownPosition="auto"
                                keepSelectedInList={true}
                                placeholder="Select State"
                              />
                              {enquirySubmit.touched.state_id &&
                                enquirySubmit.errors.state_id ? (
                                <span className="errorMsg">
                                  {enquirySubmit.errors.state_id}
                                </span>
                              ) : null}
                            </div>

                            <div className="input-item mb-5 input-flex input-height">
                              <InputCom
                                placeholder="City"
                                label="City*"
                                type="text"
                                id="city"
                                name="city"
                                onChange={enquirySubmit.handleChange}
                                onBlur={enquirySubmit.handleBlur}
                                value={enquirySubmit.values.city}
                                className={`input-height input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none input-height`}
                              />
                              {enquirySubmit.touched.city &&
                                enquirySubmit.errors.city ? (
                                <span className="errorMsg mt-6">
                                  {enquirySubmit.errors.city}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="input-state">
                          <div className="input-body input-flex">
                            <div className="input-item mb-5 input-flex input-height">
                              <InputCom
                                placeholder="Address/Delivery "
                                label="Address*"
                                type="text"
                                id="address"
                                name="address"
                                onChange={enquirySubmit.handleChange}
                                onBlur={enquirySubmit.handleBlur}
                                value={enquirySubmit.values.address}
                                className={`input-height input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none input-height`}
                              />
                              {enquirySubmit.touched.address &&
                                enquirySubmit.errors.address ? (
                                <span className="errorMsg mt-6">
                                  {enquirySubmit.errors.address}
                                </span>
                              ) : null}
                            </div>

                            <div className="input-item mb-5 input-flex input-height">
                              <InputCom
                                placeholder="Pincode "
                                label="Pincode*"
                                type="text"
                                id="pincode"
                                name="pincode"
                                onChange={enquirySubmit.handleChange}
                                onBlur={enquirySubmit.handleBlur}
                                value={enquirySubmit.values.pincode}
                                className={`input-height input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none input-height`}
                              />
                              {enquirySubmit.touched.pincode &&
                                enquirySubmit.errors.pincode ? (
                                <span className="errorMsg mt-6">
                                  {enquirySubmit.errors.pincode}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="signin-area mb-3.5">
                        <div className="flex justify-center">
                          <button
                            type="submit"
                            disabled={isDeliverLoading}
                            className="black-btn mt-6 text-sm text-white w-[170px] h-[50px] font-semibold flex justify-center bg-purple items-center send-now"
                          >
                            <span>
                              {isDeliverLoading
                                ? "Sending..."
                                : "Submit Enquiry"}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <>
          <div className="lg:flex relative">
            <div className="w-full lg:h-full bg-white flex flex-col justify-center sm:p-10 p-5 border border-[#E0E0E0]">
              <div className="w-full">
                <h1 className="text-[34px] font-bold leading-[74px] text-qblack">
                  Register First
                </h1>
                <form onSubmit={formik.handleSubmit}>
                  <div className="input-area">
                    <div className="input-item mb-4 mt-4">
                      <label>
                        <input
                          name="customer_type"
                          type="radio"
                          value="BUYER"
                          checked={formik.values.customer_type === "BUYER"}
                          onChange={formik.handleChange}
                        />
                        BUYER
                      </label>

                      {/* <label>
                        <input
                          name="customer_type"
                          type="radio"
                          value="SELLER"
                          checked={formik.values.customer_type === "SELLER"}
                          onChange={formik.handleChange}
                        />
                        SELLER
                      </label> */}
                      {formik.touched.customer_type &&
                        formik.errors.customer_type ? (
                        <span classcustomer_type="errorMsg ">
                          {formik.errors.customer_type}
                        </span>
                      ) : null}
                    </div>

                    <div className="input-item mb-4 mt-4">
                      <InputCom
                        placeholder="Full Name"
                        label="Full Name*"
                        type="text"
                        id="name"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        inputClasses="h-[50px]"
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <span className="errorMsg ">{formik.errors.name}</span>
                      ) : null}
                    </div>

                    <div className="input-item mb-4 mt-4">
                      <InputCom
                        placeholder="Email Address"
                        label="Email Address*"
                        type="email"
                        id="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        inputClasses="h-[50px]"
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <span className="errorMsg ">{formik.errors.email}</span>
                      ) : null}
                    </div>

                    <div className="input-item mb-4 mt-4">
                      <InputCom
                        placeholder="Mobile Number"
                        label="Mobile Number*"
                        type="text"
                        id="mobile"
                        name="mobile"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mobile}
                        className={`input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none`}
                      />
                      {formik.touched.mobile && formik.errors.mobile ? (
                        <span className="errorMsg ">
                          {formik.errors.mobile}
                        </span>
                      ) : null}
                    </div>

                    <div className="input-item mb-4 mt-4">
                      <InputCom
                        placeholder="Company Name"
                        label="Company Name*"
                        type="text"
                        id="company_name"
                        name="company_name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.company_name}
                        className={`input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none`}
                      />
                      {formik.touched.company_name &&
                        formik.errors.company_name ? (
                        <span className="errorMsg ">
                          {formik.errors.company_name}
                        </span>
                      ) : null}
                    </div>

                    <div className="input-item mb-4 mt-4">
                      <InputCom
                        placeholder="GST Number"
                        label="GST Number"
                        type="text"
                        id="gst_number"
                        name="gst_number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.gst_number}
                        className={`input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none`}
                      />
                      {/* {formik.touched.gst_number && formik.errors.gst_number ? (
                      <span className="errorMsg ">
                        {formik.errors.gst_number}
                      </span>
                    ) : null} */}
                    </div>
                    <div className="input-item mb-4 mt-4">
                      <InputCom
                        placeholder="Pan Number"
                        label="Pan Number"
                        type="text"
                        id="pan_number"
                        name="pan_number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.pan_number}
                        className={`input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none`}
                      />
                      {/* {formik.touched.pan_number && formik.errors.pan_number ? (
                      <span className="errorMsg ">
                        {formik.errors.pan_number}
                      </span>
                    ) : null} */}
                    </div>

                    <div className="signin-area mb-4 mt-4">
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          disabled={isLoading}
                          className="black-btn text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center send-now"
                        >
                          <span>
                            {isLoading ? "Creating..." : "Create Account"}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="w-full lg:h-full bg-white flex flex-col sm:p-10 p-5 border border-[#E0E0E0]">
              <div className="w-full">
                <div className="title-area flex flex-col relative text-center mb-7">
                  <h1 className="text-[34px] font-bold leading-[74px] text-qblack">
                    Login
                  </h1>

                  {isSendOtp ? (
                    <form onSubmit={otpVerify.handleSubmit}>
                      <div className="input-area input-area-cart">
                        <div className="input-item mb-5">
                          <InputCom
                            placeholder="OTP"
                            label="OTP*"
                            type="text"
                            id="otp"
                            name="otp"
                            onChange={otpVerify.handleChange}
                            onBlur={otpVerify.handleBlur}
                            value={otpVerify.values.otp}
                            className={`input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none`}
                          />
                          {otpVerify.touched.otp && otpVerify.errors.otp ? (
                            <span className="errorMsg">
                              {otpVerify.errors.otp}
                            </span>
                          ) : null}
                        </div>
                        <div className="signin-area mb-3.5">
                          <div className="flex justify-center">
                            <button
                              type="submit"
                              disabled={isOTPLoading}
                              className="black-btn mb-6 text-sm text-white w-[100px] h-[50px] font-semibold flex justify-center bg-purple items-center send-now"
                            >
                              <span>
                                <span>
                                  {isOTPLoading ? "Verifying..." : "Verify OTP"}
                                </span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <form onSubmit={sendOtp.handleSubmit}>
                      <div className="input-area input-area-cart">
                        <div className="input-item mb-5">
                          <InputCom
                            placeholder="Phone"
                            label="Phone*"
                            type="text"
                            id="mobile"
                            name="mobile"
                            onChange={sendOtp.handleChange}
                            onBlur={sendOtp.handleBlur}
                            value={sendOtp.values.mobile}
                            className={`input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none`}
                          />
                          {sendOtp.touched.mobile && sendOtp.errors.mobile ? (
                            <span className="errorMsg">
                              {sendOtp.errors.mobile}
                            </span>
                          ) : null}
                        </div>
                        <div className="signin-area mb-3.5">
                          <div className="flex justify-center">
                            <button
                              type="submit"
                              disabled={isLoading}
                              className="black-btn mb-6 text-sm text-white w-[100px] h-[50px] font-semibold flex justify-center bg-purple items-center send-now"
                            >
                              <span>
                                {isLoading ? "Sending..." : "Send OTP"}
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
