import React, { useEffect, useState } from "react";
import Facebook from "../../../Helpers/icons/Facebook";
import Instagram from "../../../Helpers/icons/Instagram";
import Youtube from "../../../Helpers/icons/Youtube";
export default function Footer({ type }) {
  const currentYear = new Date().getFullYear();

  const [settingData, setSettingData] = useState(null);
  useEffect(() => {
    const storedSetting = localStorage.getItem("settingRow");
    if (storedSetting) {
      setSettingData(JSON.parse(storedSetting));
    }
  }, []);

  return (
    <>
      <footer className="footer-section-wrapper footer-bg print:hidden">
        <div className="container-x block mx-auto pt-[20px]">
          <div className="grid grid-cols-12 mb-[10px]">
            <div className="col-span-6 order-1 lg:order-none md:col-span-6 lg:col-span-3 mb-10 lg:mb-0">
              <div>
                <div className="mb-5">
                  <h6 className="text-[18] font-500 text-[#fff]">
                    <b>FEATURE</b>
                  </h6>
                </div>
                <div className="footer-link-all">
                  <ul className="flex flex-col space-y-4">
                    <li>
                      <a href="/terms-condition">
                        <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Terms Condition
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/contact">
                        <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Contact Us
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/contact">
                        <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          General Enquiries
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/contact">
                        <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Supplier Enquiries
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/contact">
                        <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Partner Enquiries
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/contact">
                        <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Investor Relations
                        </span>
                      </a>
                    </li>

                    <li>
                      <a href="/faq">
                        <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          FAQ
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-span-12 order-3 md:col-span-6 lg:order-none lg:col-span-3 mb-10 lg:mb-0">
              <div className="mb-5">
                <h6 className="text-[18] font-500 text-[#fff]"><b>FEATURE</b></h6>
              </div>
              <div>
                <ul className="flex flex-col space-y-4 ">
                  <li>
                    <a href="/about">
                      <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        About Us
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="/teams">
                      <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        Our Teams
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="/blogs">
                      <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        Blogs
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="/news">
                      <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        News
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-span-12 order-4 md:col-span-6 lg:order-none lg:col-span-3 mb-10 lg:mb-0 w-100">
              <div>
                <div className="mb-5">
                  <h6 className="text-[18] font-500 text-[#fff]">
                    <b>SOCIAL LINK</b>
                  </h6>
                </div>
                <div>
                  <ul className="flex flex-col space-y-4 ">
                    {/* <li>
                      <span className="text-[#fff] text-[15px]">
                        <b>Address: </b>
                        {settingData?.company_address}
                      </span>
                    </li> */}
                    <li>
                      <span className="text-[#fff] text-[15px]">
                        <b>Email: </b>
                        {settingData?.email}
                      </span>
                    </li>
                    {/* <li>
                      <span className="text-[#fff] text-[15px]">
                        <b>Mobile: </b>
                        {settingData?.mobile}
                      </span>
                    </li> */}
                  </ul>
                </div>

              </div>
            </div>

            <div className="col-span-6 order-2 md:col-span-6 lg:order-none lg:col-span-3 mb-10 lg:mb-0 w-100">
              <div>
                <div className="mb-5">
                  <h6 className="text-[18] font-500 text-[#fff]">
                    <b className="text-xl">Ezybuying<span className="text-orange-500">.in</span></b>
                  </h6>
                </div>
                <div className="flex space-x-5 items-center mt-5 social-icon">
                  <a href="#">
                    <Instagram className="fill-current text-qwhite hover:text-qblack" />
                  </a>
                  <a href="#">
                    <Facebook className="fill-current text-qwhite hover:text-qblack" />
                  </a>
                  <a href="#">
                    <Youtube className="fill-current text-qwhite hover:text-qblack" />
                  </a>
                </div>
              </div>
            </div>

          </div>
          <div className="bottom-bar border-t border-qwhite-border lg:h-[82px] lg:flex justify-center items-center">
            <div className="flex lg:space-x-5 justify-center items-center mb-3">
              <span className="sm:text-base text-[10px] font-300"
                style={{ color: "#fff" }}>
                ©{currentYear}
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  className="font-500  mx-1"
                  style={{ color: "#fff" }}
                >
                  Ezybuying
                </a>
                All rights reserved
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
